import { useSearchParams } from "@solidjs/router";
import { createResource, For, Show, Suspense } from "solid-js";
import { searchFaq } from "~/lib/fetcher";
import { debounce } from "@solid-primitives/scheduled";
import type { Faqs } from "~/types/drupal_jsonapi";
import CommonFirstScreenImage from "~/components/shared/CommonFirstScreenImage";
import { InputTypeText } from "~/components/Forms/Fields/Base/InputTypeText";
import Accordion from "@corvu/accordion";
import { useEventsContext } from "~/contexts/EventsContext";
import {
  AutopromosRenderer,
  mayHaveShortcode,
} from "~/components/shared/Autopromos/AutopromosRenderer";

import "~/components/shared/WysiwygComponents.css";
import "~/components/shared/CommonPageStyle.css";
import "./FaqPage.css";

import IconClose from "~/img/icons/close.svg";
import IconExpandMore from "~/img/icons/expand_more.svg";
import GtmVars from "../shared/Trackers/GtmVars";

export function FaqPage(props: Faqs) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [, { sendClick }] = useEventsContext();

  const [matchingFaqs, { mutate }] = createResource(
    () => searchParams.search,
    searchFaq,
  );

  const debouncedOnInput = debounce(async (search: string | undefined) => {
    setSearchParams({
      search: search,
    });

    if (!search || search.trim() === "") {
      mutate(undefined);
    }
  }, 200);

  const gtmValues = () => {
    return {
      "all.pageType": "other",
      "all.mainCategory": "#FAQ",
      "all.category": "Non défini",
      "all.subCategory": "Non défini",
      "all.subsubCategory": "Non défini",
      "all.subsubsubCategory": "Non défini",
      template: "faq",
    };
  };

  return (
    <>
      <GtmVars values={gtmValues()} />
      <article class="common-page-style faq-page" data-ga-zone="in-page">
        <CommonFirstScreenImage image="/images/faq-bg.jpg" alt="" />

        <div class="outer-content-area">
          <section class="content-area">
            <div class="inner-faq">
              <div data-test="introduction">
                <h1>Toutes les réponses à vos questions</h1>
              </div>

              <div class="field-wrapper" data-test="faqs-search">
                <Show when={matchingFaqs.loading}>
                  <div class="spinner">
                    <i aria-hidden="true" class="loading-spinner" />
                  </div>
                </Show>
                <InputTypeText
                  name="faq-search"
                  label="Mot-clé ou question"
                  value={searchParams.search ?? ""}
                  onInput={(e) =>
                    e.currentTarget.value !== ""
                      ? debouncedOnInput(e.currentTarget.value)
                      : debouncedOnInput(undefined)
                  }
                  help={`Exemples de recherche : "TVA réduite", "PTZ", "Pinel"`}
                />

                <Show when={matchingFaqs()?.items?.length > 0}>
                  <span class="entries" data-test="entries">
                    {matchingFaqs()!.items.length}{" "}
                    {matchingFaqs()!.items.length > 1 ? "réponses" : "réponse"}
                  </span>
                </Show>
                <Show when={searchParams.search}>
                  <button
                    class="btn btn-icon"
                    onClick={() => {
                      debouncedOnInput(undefined);
                    }}
                    type="button"
                    aria-label="Réinitialiser"
                    data-test="reset"
                  >
                    <i aria-hidden="true" class="cog-icon">
                      <IconClose />
                    </i>
                  </button>
                </Show>
              </div>

              <Suspense>
                <Show when={matchingFaqs()}>
                  <div class="query-faqs" data-test="results-faqs">
                    <Accordion collapseBehavior="hide">
                      <For each={matchingFaqs()?.items}>
                        {(faq) => (
                          <Accordion.Item
                            as="div"
                            class="node-qa"
                            data-test="qa"
                          >
                            <Accordion.Trigger
                              as="h2"
                              onClick={() => {
                                sendClick("btn-faq", "faq");
                              }}
                              class="question-h2"
                              data-test="question"
                            >
                              {faq.title}
                              <i aria-hidden="true" class="cog-icon">
                                <IconExpandMore />
                              </i>
                            </Accordion.Trigger>
                            <Accordion.Content data-test="answer">
                              <Show
                                when={mayHaveShortcode(faq.field_answer.value)}
                                fallback={
                                  <div
                                    class="answer-content"
                                    innerHTML={faq.field_answer.value}
                                  />
                                }
                              >
                                <div class="answer-content">
                                  <AutopromosRenderer
                                    text={faq.field_answer.value}
                                    fromFieldName="field_text"
                                  />
                                </div>
                              </Show>
                            </Accordion.Content>
                          </Accordion.Item>
                        )}
                      </For>
                    </Accordion>
                  </div>
                </Show>
              </Suspense>

              <p class="contact">
                Vous n'avez pas trouvé votre question ? Contactez-nous via{" "}
                <a href="mailto:service-client@cogedim.com">
                  service-client@cogedim.com
                </a>{" "}
                pour poser votre question. Nous vous répondrons sous 24 h
                ouvrées.
              </p>

              <Show when={props.items.length > 0}>
                <div class="top-faqs" data-test="faqs-promoted">
                  <p class="top-faqs-h2">Questions les plus fréquentes</p>
                  <Accordion collapseBehavior="hide">
                    <For each={props.items}>
                      {(item) => (
                        <Accordion.Item as="div" class="node-qa" data-test="qa">
                          <Accordion.Trigger
                            as="h2"
                            onClick={() => {
                              sendClick("btn-faq", "faq");
                            }}
                            class="question-h2"
                            data-test="question"
                          >
                            {item.title}
                            <i aria-hidden="true" class="cog-icon">
                              <IconExpandMore />
                            </i>
                          </Accordion.Trigger>
                          <Accordion.Content data-test="answer">
                            <Show
                              when={mayHaveShortcode(item.field_answer.value)}
                              fallback={
                                <div
                                  class="answer-content"
                                  innerHTML={item.field_answer.value}
                                />
                              }
                            >
                              <div class="answer-content">
                                <AutopromosRenderer
                                  text={item.field_answer.value}
                                  fromFieldName="field_text"
                                />
                              </div>
                            </Show>
                          </Accordion.Content>
                        </Accordion.Item>
                      )}
                    </For>
                  </Accordion>
                </div>
              </Show>
            </div>
          </section>
        </div>
      </article>
    </>
  );
}
