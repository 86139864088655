import { Title } from "@solidjs/meta";
import { createAsync, type RouteDefinition } from "@solidjs/router";
import { Suspense, Show } from "solid-js";
import { FaqPage } from "~/components/Faq/FaqPage";
import { MetaTags } from "~/components/shared/MetaTags";
import { useEventsContext } from "~/contexts/EventsContext";
import { getFaqsTop } from "~/lib/fetcher";
import type { Faqs } from "~/types/drupal_jsonapi";

export const route = {
  load() {
    void getFaqsTop();
  },
} satisfies RouteDefinition;

export default function Faq() {
  const faqs = createAsync(() => getFaqsTop(), {
    name: "faqs",
    deferStream: true,
  });

  const [, { setTemplate }] = useEventsContext();
  setTemplate("faq");

  const metaDesc = {
    tag: "meta",
    attributes: {
      name: "description",
      content:
        "Vous avez des questions sur l'immobilier neuf ? Cogedim vous dit tout ce qu’il faut savoir pour votre premier achat ou pour investir.",
    },
  };

  return (
    <>
      <Suspense>
        <Show when={faqs()}>
          <Title>Foire Aux Questions</Title>
          <MetaTags tags={[metaDesc]} />
          <FaqPage items={faqs()?.items as Faqs["items"]} />
        </Show>
      </Suspense>
    </>
  );
}
